@use '@UIkit/styles/typography/_text.scss';
@use '@UIkit/styles/typography/_heading.scss';
.admin-details {
	.edi-create-field-line {
		padding-bottom: 8px;

		.edi-fieldset-label {
			@extend .body-short_01;
			color: $color-grey-50;

			.ui-label-text {
				color: inherit !important;
				&::after {
					content: ':';
				}
			}
		}
		.edi-value-label {
			@extend .body-short_01;
		}
	}
}
