.canvasjs-chart-container {
	.canvasjs-chart-toolbar {
		.buttonHidden {
			white-space: nowrap;
		}
	}
}

.canvasjs-chart-tooltip {
	overflow-y: scroll;
	pointer-events: auto !important;
	max-height: 80vh;
}
