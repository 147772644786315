.edi-create-field-line {
	margin-right: 10px;
	margin-left: 10px;
	margin-bottom: 5px;

	label:only-of-type {
		padding: 8px 0 0 0;
	}
}
.edi-empty-label {
	color: #c0c0c0;
	font-size: 13px;
}
