/* -------- edi-main-header-panel - верхняя панель -------- */
.edi-viewport .edi-main-header-panel {
	@keyframes notification-loader-animation {
		0% {
			transform: translate(-50%, -50%) rotate(0deg);
		}
		100% {
			transform: translate(-50%, -50%) rotate(360deg);
		}
	}

	.notification-button__loading::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 20px;
		border-radius: 100%;
		border: 4px solid;
		border-color: #ffffff #ffffff #ffffff transparent;
		opacity: 0.5;
		animation: notification-loader-animation 1.2s linear infinite;
	}
}
