@use '@UIkit/styles/typography/_text.scss';
@import '@UIkit/styles/global/Colors/Variables.scss';
@import '@UIkit/styles/global/Colors/Colors.scss';
* {
	color: $color-dark;
}

/* -------- общие стили -------- */
.x-viewport {
	> .x-body.edi-viewport {
		position: absolute;
	}
}

.edi-viewport {
	//&.edi-viewport-admin {
	//    .edi-main-header-panel {
	//        .x-panel-body {
	//            .x-panel-body-default {
	//                background-color: #1d252c;
	//            }
	//        }
	//    }
	//}

	//.x-body{
	//    color:       black;
	//    font-size:   13px;
	//    font-family: 'Roboto', arial, verdana, sans-serif;
	//    background:  #f5f5f5;
	//}

	//.x-body,
	//.x-panel-body-default,
	//.x-panel-body-default-framed {
	//    font-weight: normal;
	//}

	.x-panel-body {
		background-color: #ffffff;
		border-color: #157fcc;
		color: black;
		border-width: 0;
		border-style: solid;
	}

	.x-panel-header-default {
		background-image: none;
		background-color: #ffffff;

		.x-panel-header-title-default {
			.x-title-text {
				color: #212121;
				font-size: 18px;
				line-height: 22px;
				padding: 1px 0 0;
				text-transform: none;
			}
		}

		.x-tool {
			background-color: transparent;

			&.x-tool-disabled {
				opacity: 0.5;
			}

			.x-tool-tool-el {
				color: #757575;
				background-color: transparent;
				font-size: 20px;
				width: 20px;
				height: 20px;
			}

			&.x-tool-over,
			&.x-tool-pressed {
				background-color: transparent;

				.x-tool-tool-el {
					color: #757575;
				}
			}
		}
	}

	.document-filter-header {
		.x-tool {
			background-color: transparent;

			&.x-tool-disabled {
				opacity: 0.5;
			}

			.x-tool-tool-el {
				color: #757575;
				background-color: transparent;
				font-size: 20px;
				width: 20px;
				height: 20px;
			}

			&.x-tool-over,
			&.x-tool-pressed {
				background-color: transparent;

				.x-tool-tool-el {
					color: #757575;
				}
			}
		}
	}

	//.edi-module-details {
	//    .x-border-layout-ct {
	//        background-color: rgb(255, 255, 255);
	//    }
	//}

	.edi-main-panel {
		> .x-panel-bodyWrap {
			> .x-panel-body {
				background-color: $color-background;
			}
		}
	}

	//.x-panel-default-framed {
	//    background: white;
	//    border-color: #157fcc;
	//    color: black;
	//    font-size: 13px;
	//    border-width: 0;
	//    border-style: solid;
	//}

	.x-css-shadow {
		box-shadow: none !important;
	}

	.x-splitter {
		&-horizontal,
		&-vertical {
			background-color: $color-background;
		}

		&-active {
			background-color: $color-grey-10;
		}
	}

	.underlined {
		border-bottom: 2px solid #d0d0d0;
	}

	.link-label {
		cursor: pointer;
		color: #08628c;
	}

	.x-btn-glyph,
	.x-action-col-glyph,
	.x-panel-header-glyph,
	.x-tab-glyph,
	.x-trigger-glyph,
	.x-column-header-sort-glyph,
	.x-action-col-icon {
		display: inline-block;
		vertical-align: middle;
		font-weight: normal;
		font-style: normal;
		font-size: 20px;
		line-height: 20px;
		height: 20px;
		width: 20px;
		text-transform: none;
		color: #8e9295;
		letter-spacing: normal;
		word-wrap: normal;
		white-space: nowrap;
	}

	a.x-btn.x-btn-default-small,
	a.x-btn.x-btn-default-toolbar-small {
		background-color: transparent;
		border: none;
		outline: none;
		box-shadow: none;

		.x-btn-inner {
			font-size: 14px;
			color: #1d252c;
		}

		.x-btn-glyph {
			font-size: 20px;
		}

		&.x-btn-over {
			background-color: #f3f4f4;
		}

		&.x-btn-pressed {
			background-color: #e8e9e9;
			&.test-button-details {
				background: transparent;
			}
		}

		&.x-btn-over,
		&.x-btn-pressed {
			box-shadow: none;

			.x-btn-glyph,
			.x-btn-inner {
				color: #1d252c;
			}
		}

		&.x-btn-pressed,
		&.x-btn-over,
		&.x-btn-focus,
		&.x-btn-focus.x-btn-pressed,
		&.x-btn-focus.x-btn-over {
			box-shadow: none;
		}

		.x-btn-arrow {
			background: none;
		}
		.x-btn-arrow-right {
			padding-right: 20px;
			position: relative;
			&:after {
				content: '\E5C5';
				font-family: 'Material Icons';
				font-style: normal;
				font-weight: normal;
				text-decoration: inherit;
				position: absolute;
				font-size: 20px;
				color: #1d252c;
				right: 0;
				top: 0;
			}
		}
		&.x-btn-menu-active {
			.x-btn-arrow-right {
				&:after {
					transform: scaleY(-1);
				}
			}
		}
		&.x-item-disabled {
			.x-btn-glyph,
			.x-btn-inner,
			.x-btn-arrow-right:after {
				color: #bbbdbf;
			}
			&.x-btn {
				cursor: default;
			}
		}
	}

	a.x-btn.edi-label-button {
		&.no-text-decoration,
		&.test-button-remove,
		&.test-button-print {
			background-color: transparent;
			padding: 12px 10px;
			&.x-btn-over {
				background-color: transparent;
			}
		}
		&.test-button-print {
			width: auto !important;
			height: auto !important;
			top: 0 !important;
		}
		padding: 12px 32px;
		color: #1d252c;
		line-height: 16px;
		text-transform: uppercase;
		background-color: #d2d3d5;
		border-radius: 3px;
		&.x-btn-over {
			background-color: #bbbdbf;
		}
	}

	a.x-btn.edi-actions-button {
		padding: 10px;
		border: none;
		outline: none;
		border-radius: 3px;
		float: right;
		margin-left: 3px;

		.x-btn-glyph {
			width: 20px;
			height: 20px;
		}

		&.x-btn-over {
			background-color: #f3f4f4;
		}

		&.x-btn-pressed {
			background-color: #e8e9e9;
		}

		&.x-btn-over,
		&.x-btn-pressed {
			box-shadow: none;

			.x-btn-glyph {
				color: #1d252c;
			}
		}

		.x-btn-arrow-right {
			padding-right: 0;
			.x-btn-button {
				padding-right: 0;
			}

			&::after {
				content: '';
				width: 0;
			}
		}
	}

	.x-toolbar-footer {
		background: #ffffff;
		border: 0;
		margin: 0;
		padding: 6px 0 6px 6px;

		a.x-btn,
		a.x-btn.x-btn-over,
		a.x-btn.x-btn-pressed {
			border-radius: 3px;
			padding: 10px;
			background-color: #3d7dca;

			.x-btn-inner,
			.x-btn-glyph {
				color: #ffffff;
			}
		}
	}

	.dir-outgoing,
	.dir-incoming,
	.dir-toself,
	.dir-notdefined {
		display: inline-block;
		padding-left: 14px;
		width: 14px;
		height: 14px;
		overflow: hidden;
	}

	.dir-incoming {
		color: #009900;
		background-image: url(images/dir-incoming.png?ver=20150702);
	}

	.dir-outgoing {
		color: #0066ff;
		background-image: url(images/dir-outgoing.png?ver=20150702);
	}

	.dir-toself {
		color: #515151;
		background-image: url(images/toself.png?ver=20150702);
	}

	.dir-notdefined {
		color: #a1a1a1;
		background-image: url(images/notdefined.png?ver=20150702);
	}

	.edi-data-error,
	.error-description,
	.edi-data-error {
		color: #ff0000;
	}

	.ui-documents-list .error-description,
	.serverErrorDetailsText,
	.poa-error-details-text {
		@extend .body-short_01;
		color: $color-dark;
		display: block;
		margin-bottom: 10px;
		padding: 16px;
		border-radius: 8px;
		white-space: normal;
		background: $color-error-opacity-10;
		overflow-wrap: anywhere;
	}

	.serverErrorDetailsText {
		margin-top: 8px;
	}

	.reset-ecd-version {
		&:before {
			color: #f34235;
		}
	}

	//documents state
	.edi-state-container {
		display: inline-block;
		max-width: 100%;
		padding: 2px 10px;
		font-size: 13px;
		line-height: normal;
		border-style: solid;
		border-width: 1px;
		border-radius: 30px;
		box-shadow: 0 4px 8px rgb(187 189 191 / 25%);
		font-family: 'Roboto', arial, verdana, sans-serif !important;
		white-space: nowrap;

		& > span {
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;
			max-width: 100%;
		}
	}
	.edi-state-container-COMPLETED,
	.edi-state-container-ARCHIVED,
	.edi-state-container-ACCEPTED,
	.edi-state-container-ACTIVE,
	.edi-state-container-WARNING_COMPLETED,
	.edi-state-container-ADMIN_REVIEW,
	.edi-state-container-APERAK_CODE_13,
	.edi-state-container-APERAK_CODE_29,
	.edi-state-container-APERAK_CODE_34,
	.instruction-state-ab-EXECUTED,
	.edi-state-container-APERAK_STATUS_29_APERAK_CODE,
	.edi-state-container-APERAK_CODE_29_DOCUMENT_NAME_CODE_222,
	.edi-state-container-POWER_OF_ATTORNEY_ACTIVE,
	.edi-state-container-POWER_OF_ATTORNEY_STATUS_withoutErrors {
		border-color: #00ac34;
	}
	.status-COMPLETED,
	.status-ARCHIVED,
	.status-ACCEPTED,
	.status-ACTIVE,
	.status-WARNING_COMPLETED,
	.status-ADMIN_REVIEW,
	.status-APERAK_CODE_13,
	.status-APERAK_CODE_29,
	.status-APERAK_CODE_34,
	.instruction-state-ab-EXECUTED,
	.status-APERAK_STATUS_29_APERAK_CODE,
	.status-APERAK_CODE_29_DOCUMENT_NAME_CODE_222,
	.status-POWER_OF_ATTORNEY_ACTIVE,
	.status-POWER_OF_ATTORNEY_STATUS_withoutErrors {
		color: #00ac34;
	}

	.edi-state-container-SELLER_REVIEW,
	.edi-state-container-DEBTOR_REVIEW,
	.edi-state-container-SENDER_REVIEW,
	.edi-state-container-RECEIVER_REVIEW,
	.edi-state-container-WAIT_RECEIVER_DECISION,
	.edi-state-container-WAIT_SENDER_DECISION,
	.edi-state-container-SENT,
	.edi-state-container-WARNING_SENT,
	.edi-state-container-RECEIVED,
	.edi-state-container-READ,
	.edi-state-container-WAIT_BANK_DECISION,
	.edi-state-container-WAIT_BANK_COMPLETE,
	.edi-state-container-SIGNED_BY_CONSUMER,
	.edi-state-container-UPD_NOTICE_COMPLETED,
	.edi-state-container-ACCEPTED-registry-line,
	.edi-state-container-UPD_NOTICE_SENT,
	.edi-state-container-PROCESSING_TITLES,
	.edi-state-container-PROCESSING_ANNUL,
	.edi-state-container-OUTGOING_DSF_DP_IZVPOL_RECEIVER,
	.edi-state-container-INCOMING_DSF_DP_IZVPOL_RECEIVER,
	.edi-state-container-DSF_DP_IZVPOL_RECEIVER,
	.edi-state-container-DSF_STATUS_4_DP_IZVPOL,
	.edi-state-container-INCOMING_DSF_DP_IZVPOL_SENDER,
	.edi-state-container-APERAK_STATUS_6_APERAK_CODE,
	.edi-state-container-APERAK_STATUS_13_APERAK_CODE,
	.edi-state-container-MANUAL_PROCESSING,
	.edi-state-container-APERAK_CODE__DOCUMENT_NAME_CODE_222,
	.edi-state-container-OTHER_DOCUMENT_NAME_CODE_222,
	.edi-state-container-POWER_OF_ATTORNEY_STATUS_poaChecking,
	.edi-state-container-GET_NUMBER,
	.edi-state-container-SIGNATURE_VERIFICATION,
	.edi-state-container-WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT,
	.edi-state-container-WAIT_SIGNATURE {
		border-color: #3d7dca;
	}
	.status-SELLER_REVIEW,
	.status-DEBTOR_REVIEW,
	.status-SENDER_REVIEW,
	.status-RECEIVER_REVIEW,
	.status-WAIT_RECEIVER_DECISION,
	.status-WAIT_SENDER_DECISION,
	.status-SENT,
	.status-WARNING_SENT,
	.status-RECEIVED,
	.status-READ,
	.status-WAIT_BANK_DECISION,
	.status-WAIT_BANK_COMPLETE,
	.status-SIGNED_BY_CONSUMER,
	.status-UPD_NOTICE_COMPLETED,
	.instruction-state-ab-ACCEPTED,
	.status-ACCEPTED-registry-line,
	.status-UPD_NOTICE_SENT,
	.status-PROCESSING_TITLES,
	.status-PROCESSING_ANNUL,
	.status-OUTGOING_DSF_DP_IZVPOL_RECEIVER,
	.status-INCOMING_DSF_DP_IZVPOL_RECEIVER,
	.status-DSF_DP_IZVPOL_RECEIVER,
	.status-DSF_STATUS_4_DP_IZVPOL,
	.status-INCOMING_DSF_DP_IZVPOL_SENDER,
	.status-APERAK_STATUS_6_APERAK_CODE,
	.status-APERAK_STATUS_13_APERAK_CODE,
	.status-MANUAL_PROCESSING,
	.status-APERAK_CODE__DOCUMENT_NAME_CODE_222,
	.status-OTHER_DOCUMENT_NAME_CODE_222,
	.status-POWER_OF_ATTORNEY_STATUS_poaChecking,
	.status-GET_NUMBER,
	.status-SIGNATURE_VERIFICATION,
	.status-WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT,
	.status-WAIT_SIGNATURE {
		color: #3d7dca;
	}

	.edi-state-container-UTOCH,
	.edi-state-container-UTOCH_COMPLETED,
	.edi-state-container-UTOCH_SIGNED_BY_CONSUMER,
	.edi-state-container-ANNUL,
	.edi-state-container-ON_ANNUL,
	.edi-state-container-ANNUL_SIGN,
	.edi-state-container-CANCELED,
	.edi-state-container-OUTGOING_CANCELED,
	.edi-state-container-OUTGOING_ON_ANNUL,
	.edi-state-container-INCOMING_CANCELED,
	.edi-state-container-INCOMING_ON_ANNUL,
	.edi-state-container-SENT_DP_PRANNUL_TO_RECEIVER,
	.edi-state-container-REJECTED,
	.edi-state-container-REJECTED_BY_RECEIVER,
	.edi-state-container-SENDING_TO_BANK_ERROR,
	.edi-state-container-BANK_DECISION_ERROR,
	.edi-state-container-ERROR,
	.edi-state-container-APERAK_CODE_27,
	.edi-state-container-SIGNED_BY_CONSUMER_short_3,
	.edi-state-container-SIGNED_BY_CONSUMER_3,
	.edi-state-container-COMPLETED_3,
	.edi-state-container-ERROR_ANNUL,
	.edi-state-container-DEACTIVATED,
	.edi-state-container-DELETED,
	.edi-state-container-APERAK_STATUS_27_APERAK_CODE,
	.edi-state-container-APERAK_CODE_12_DOCUMENT_NAME_CODE_222,
	.edi-state-container-APERAK_CODE_27_DOCUMENT_NAME_CODE_222,
	.edi-state-container-POWER_OF_ATTORNEY_REVOKED,
	.edi-state-container-POWER_OF_ATTORNEY_EXPIRED,
	.edi-state-container-POWER_OF_ATTORNEY_STATUS_errors {
		border-color: #e82000;
	}
	.status-UTOCH,
	.status-UTOCH_COMPLETED,
	.status-UTOCH_SIGNED_BY_CONSUMER,
	.status-ANNUL,
	.status-ON_ANNUL,
	.status-ANNUL_SIGN,
	.status-CANCELED,
	.status-OUTGOING_CANCELED,
	.status-OUTGOING_ON_ANNUL,
	.status-INCOMING_CANCELED,
	.status-INCOMING_ON_ANNUL,
	.status-SENT_DP_PRANNUL_TO_RECEIVER,
	.status-REJECTED,
	.status-REJECTED_BY_RECEIVER,
	.status-SENDING_TO_BANK_ERROR,
	.status-BANK_DECISION_ERROR,
	.status-ERROR,
	.status-APERAK_CODE_27,
	.instruction-state-ab-REJECTED,
	.status-DEACTIVATED,
	.status-ERROR_ANNUL,
	.status-SIGNED_BY_CONSUMER_short_3,
	.status-SIGNED_BY_CONSUMER_3,
	.status-COMPLETED_3,
	.status-DELETED,
	.status-APERAK_STATUS_27_APERAK_CODE,
	.status-APERAK_CODE_27_DOCUMENT_NAME_CODE_222,
	.status-APERAK_CODE_12_DOCUMENT_NAME_CODE_222,
	.status-POWER_OF_ATTORNEY_REVOKED,
	.status-POWER_OF_ATTORNEY_EXPIRED,
	.status-POWER_OF_ATTORNEY_STATUS_errors {
		color: #e82000;
	}

	.edi-state-container-SIGNED_BY_CONSUMER_short_2,
	.edi-state-container-SIGNED_BY_CONSUMER_2,
	.edi-state-container-COMPLETED_2,
	.edi-state-container-APERAK_STATUS_23_APERAK_CODE,
	.edi-state-container-NOT_DEFINED {
		color: darkorange;
	}
	.status-SIGNED_BY_CONSUMER_short_2,
	.status-SIGNED_BY_CONSUMER_2,
	.status-COMPLETED_2,
	.status-APERAK_STATUS_23_APERAK_CODE,
	.status-NOT_DEFINED {
		color: darkorange;
	}

	.edi-state-container-DRAFT {
		border-color: #8e9295;
	}
	.status-DRAFT {
		color: #8e9295;
	}

	.edi-state-container-CODES_SENT,
	.edi-state-container-SUCCESS_ANNUL,
	.edi-state-container-APERAK_STATUS_34_APERAK_CODE,
	.edi-state-container-POWER_OF_ATTORNEY_ON_REGISTRATION,
	.edi-state-container-POWER_OF_ATTORNEY_SIGNED {
		border-color: #00afab;
	}
	.status-CODES_SENT,
	.status-SUCCESS_ANNUL,
	.status-APERAK_STATUS_34_APERAK_CODE,
	.status-POWER_OF_ATTORNEY_ON_REGISTRATION,
	.status-POWER_OF_ATTORNEY_SIGNED {
		color: #00afab;
	}

	.edi-state-good {
		color: #090;
		font-weight: 700;
	}
	.edi-state-error {
		color: red;
		font-weight: 500;
		&.edi-empty-label {
			color: red;
		}
	}

	.documents-list {
		width: 100%;
		border-collapse: collapse;
		table-layout: fixed;
		th,
		td {
			text-align: right;
		}
		td {
			padding: 2px;
		}
	}

	.textarea-fit {
		.x-form-item-label {
			margin: 0;
			font-size: 15px;
			font-weight: 600;
		}
		.x-form-textarea-body {
			textarea.x-form-text {
				margin: 0;
				padding: 5px;
				border: 1px solid #c0c0c0;
				box-shadow: none;
			}
		}
	}

	.x-btn.x-form-file-btn {
		border-radius: 3px;
		padding: 10px;
		background-color: #3d7dca;
		&.x-btn-focus {
			box-shadow: none;
		}
	}

	.x-tab-icon-el {
		opacity: 1;
		width: 24px;
		height: 24px;
	}

	.ui-table {
		.x-grid-body {
			.x-grid-item {
				.x-grid-row {
					&.edi-timer-enabled {
						.x-grid-td {
							background-color: rgba(186, 239, 186, 0.4);
						}
					}

					&.edi-timer-disabled {
						.x-grid-td {
							background-color: rgba(186, 186, 186, 0.2);
						}
					}
				}
			}
		}
	}

	.edi-timers-global-switch-disabled label {
		background-color: rgba(239, 186, 186, 0.4);
		line-height: 20px;
	}
	.edi-timers-global-switch-enabled label {
		background-color: rgba(186, 239, 186, 0.4);
		line-height: 20px;
	}

	.x-btn-icon-left {
		justify-content: flex-start;
	}

	.edi-data-good {
		color: #090;
	}
	.edi-info {
		color: #06f;
		font-weight: 700;
	}

	.edi-storage-cluster-node-master {
		.x-grid-cell-inner {
			font-weight: 700;
		}
	}

	.edi-cart-series-toggle-disabled {
		opacity: 0.7;
	}

	.x-tip-default,
	.x-tip-form-invalid {
		background-color: #eaf3fa;
		border-color: #e1e1e1;
		.x-tip-body-default,
		.x-tip-body-form-invalid {
			color: #000000;
			font-weight: 400;
			padding: 3px;
		}
	}

	.x-tip-form-invalid {
		width: 310px !important;
	}
	.x-tip-form-invalid,
	.x-tip-default {
		.x-tip-body-form-invalid {
			width: 100% !important;
			background: unset;
			display: flex;
			&:before {
				color: #d94e37;
				font-family: Material Icons;
				font-size: 17px;
				content: '\e000';
				margin-right: 5px;
			}
		}
		.x-tip-anchor {
			overflow: hidden;
			height: 10px;
			width: 10px;
			border: 5px solid #e1e1e1;
		}
		.x-tip-anchor-top {
			border-top-color: transparent;
			border-left-color: transparent;
			border-right-color: transparent;
		}
	}

	.edi-formtr-panel {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9999999;
		border-radius: 3px;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
		overflow: hidden;
		background: #fff;
		padding: 15px;
	}

	.text-container-no-flex {
		.x-header-text {
			cursor: pointer;
		}
		.x-header-text {
			margin-right: 10px;
		}
	}

	.edi-grey-icon {
		.x-btn-glyph,
		.x-tool-tool-el {
			color: #8e9295 !important;
		}
	}
	.edi-white-button {
		.x-btn-glyph,
		.x-tool-tool-el {
			color: #fff !important;
		}
	}
	.font-icon-disable {
		color: #e3e3e3;
	}
	a.x-btn.x-btn-default-small.edi-packing-btn {
		border-radius: 3px;
		padding: 10px;
		background-color: #3d7dca;
		.x-btn-inner,
		.x-btn-icon-el {
			color: #fff;
		}
	}
	.edi-package-buttons {
		margin-top: 25px;
		.x-autocontainer-innerCt {
			display: flex;
			justify-content: flex-end;
		}
	}

	.edi-button-add {
		&:before {
			font-size: 20px;
			content: '\e148';
			font-family: 'Material Icons', sans-serif;
		}
	}

	.edi-window-notice {
		display: block;
		position: absolute;
		z-index: 20000 !important;
		right: 25px !important;
		top: 70px;
		margin: 25px;
		background: white;
		border-radius: 3px;
		box-shadow: 0 4px 16px rgba(187, 189, 191, 0.5);

		.edi-main-container {
			/* used when no ico */
			margin-left: 14px;
		}
		&-show {
			@keyframes slidein {
				from {
					right: -100%;
				}

				to {
					right: 25px;
				}
			}

			right: 25px;

			animation-duration: 500ms;
			animation-name: slidein;
		}
		&-hide {
			@keyframes slideout {
				from {
					right: 25px;
				}

				to {
					right: -100%;
				}
			}

			right: -100%;

			animation-duration: 500ms;
			animation-name: slideout;
		}

		.edi-main-container {
			/* used when no ico */
			margin-left: 14px;
		}

		.edi-window-notice-body {
			padding: 5px 5px 0 5px;

			.edi-leftside-ico {
				font-family: 'Material Icons';
				font-size: 24px;
				line-height: 24px;
				padding: 5px;
			}

			.edi-rightside-main-container {
				margin-left: 8px;

				.edi-rightside-title,
				.edi-rightside-main-text,
				.edi-rightside-main-text-additional {
					font-family: 'Roboto', arial, verdana, sans-serif;
					font-style: normal;
					font-weight: normal;
					letter-spacing: 0;
				}

				.edi-rightside-title {
					font-size: 20px;
					line-height: 28px;
					color: #1d252c;
					padding: 3px 5px;
					overflow: auto;
				}

				.edi-rightside-main-text,
				.edi-rightside-main-text-additional {
					font-size: 14px;
					line-height: 20px;
					color: #333a41;
					padding: 5px;

					p {
						margin-block-start: 0;
						margin-block-end: 0.3em;
						margin-inline-start: 0;
						margin-inline-end: 0;
					}
				}

				.edi-rightside-vertical-toolbar {
					padding: 6px 0 0;
					display: inline;
					position: absolute;

					.x-btn-default-small {
						padding: 0;
					}
				}
			}
		}

		.edi-bottom-toolbar {
			padding: 0 15px 0;

			.x-btn {
				box-shadow: none;
				background: none !important;
				border: none;

				.x-btn-inner {
					font-family: 'Roboto', arial, verdana, sans-serif;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					font-size: 14px;
					text-align: center;
					letter-spacing: 0.75px;
					text-transform: uppercase;
					color: #0e85f3 !important;
				}
			}
		}
	}

	.edi-info-state-container {
		display: inline-block;
		max-width: 100%;
		padding: 2px 10px;
		font-size: 13px;
		line-height: normal;
		overflow: hidden;
		text-overflow: ellipsis;
		font-family: 'Roboto', arial, verdana, sans-serif !important;
	}

	.edi-storage-metrics {
		padding: 0 !important;
		.edi-storage-metrics-header {
			background: #d1d1d1;
			.edi-actions-button {
				padding: 0 10px 0 0;
			}
		}
	}
}

.compare-area {
	border: 1px solid $color-grey-10;
	padding-bottom: 10px;
	.del {
		background: $color-grey-10;
	}
	.ins {
		background: $color-blue-10;
	}
	.changed-line {
		background: $color-error-opacity-5;
	}
	.change {
		background: $color-error-opacity-10;
	}
	pre {
		white-space: pre-wrap;
	}
}

.x-menu-item-default.x-menu-item-separator.menu-separator {
	height: 1px;
	padding: 0;
	background: $color-grey-20;
}
