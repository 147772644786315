@use '@UIkit/styles/typography/_text.scss';
@import '@UIkit/styles/global/Colors/Variables.scss';

$this: '.drag-file';

.edi-viewport {
	#{$this} {
		&.edi-component-clickable {
			cursor: pointer;

			label {
				cursor: pointer;
			}
		}

		//min-height: 110px;

		&#{$this}-read-only {
			min-height: unset;

			#{$this}-uploaded-file {
				margin-top: unset;
			}
		}

		.x-panel-body {
			background-color: transparent;
		}

		#{$this}-container {
			background-color: $color-blue-opacity-10;
			border: 2px dashed $color-blue-opacity-15;
			border-radius: 8px;
			text-align: center;
			padding: 25px;

			#{$this}-load {
				&-button {
					display: inline-block;
					padding: 0;
					margin-left: 8px;

					.ui-button-inner {
						@extend .body-short_01;
					}
				}

				&-label {
					@extend .body-short_01;
					color: $color-grey-50;
				}

				&-allowFileType {
					@extend .caption_01;
					color: $color-grey-30;
				}
			}

			&.active.x-panel {
				background-color: $color-blue-opacity-10;
				border: 2px dashed $color-blue-opacity-15;

				&:after {
					//этот элемент нужен что бы накрыть весь контейнер и предотвратить
					// срабатывание ивентов перемещения мыши когда тащим файл
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}

		#{$this}-container-with-errors {
			#{$this}-container {
				background-color: $color-error-opacity-10;
				border: 2px dashed $color-dark-opacity-20;
				margin-bottom: 4px;
			}

			#{$this}-load-needUploadFile {
				@extend .caption_01;
				color: $color-error;
				text-align: left;
			}

			#{$this}-uploaded-file {
				background-color: $color-error-opacity-10;

				.file-info-wrapper {
					.file-info-error {
						color: $color-error;
					}
				}
			}
		}

		&-uploaded-file {
			background-color: $color-dark-opacity-5;
			border-radius: 4px;
			padding: 4px 9px 4px 11px;
			margin-top: 15px;

			.x-autocontainer-innerCt {
				display: flex;
				justify-content: space-between;
			}

			.file-info {
				margin-left: 25px;
				height: 100%;

				&-name {
					@extend .body-short_01;
					color: $color-dark;
				}

				&-wrapper {
					span {
						@extend .caption_01;
						color: $color-grey-30;
					}

					.file-info-fileSize {
						margin-left: 8px;
						position: relative;

						&:before {
							content: '';
							width: 2px;
							height: 2px;
							background-color: $color-grey-50;
							border-radius: 50%;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							left: -5px;
						}
					}
				}

				&:before {
					content: '\E226';
					font-family: 'Material Icons', sans-serif;
					color: $color-grey-50;
					position: absolute;
					top: 0;
					left: -5px;
					font-size: 23px;
					line-height: 35px;
				}
			}
		}
	}
}
