@use '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables';

.edi-main-header-panel {
	box-shadow: 0 0 8px $color-dark-opacity-10;
}

$this: '.ui-headercontrols';

#{$this} {
	background-color: $color-blue;

	.ui-header-logo {
		margin: 0 0 0 28px;
		width: 200px;
		height: 40px;
		background-repeat: no-repeat;
		background-position: left center;
	}

	.ui-button {
		.ui-button-button {
			color: $color-white;

			&:after {
				color: inherit;
			}
			.ui-button-glyph {
				color: inherit;
			}
		}

		&-over,
		&-menu-active {
			.ui-button-button {
				background-color: $color-white-opacity-10;
			}
		}
	}
}

#{$this}__goto-menu-item {
	&.x-menu-item.x-menu-item {
		border-bottom: 1px solid $color-grey-20;
	}

	.x-menu-item-text {
		display: flex;
		width: 100%;
	}

	#{$this}__goto-card {
		flex-grow: 1;
		margin-right: 16px;
		border-radius: 8px;
		background-color: $color-grey-3;
		padding: 8px 16px;

		&-text {
			@extend .caption_01;
			color: $color-grey-50;
		}

		&-logo {
			margin-top: 8px;
			background-repeat: no-repeat;
			background-size: contain;
			height: 14px;

			&-ediweb {
				background-image: url('./images/ediweb_logo.svg');
			}
		}
	}
}

.edi-tooltip-notify {
	width: 300px;
	background-color: $color-white;
	color: $color-dark;
	border-radius: 10px;
	padding: 15px;
	height: 71px;
	position: fixed;
	display: flex;
	top: 60px;
	z-index: 10000;
	transition: opacity 0.3s;
	box-shadow: 0 4px 16px $color-grey-50;

	&:after {
		display: flex;
		content: '';
		position: absolute;
		width: 12px;
		height: 12px;
		left: 12%;
		top: 0;
		transform: translate(-50%, -50%) rotate(-45deg);
		background-color: $color-white;
		border: 1px solid $color-white;
	}

	&:before {
		font-family: 'Material Icons', sans-serif;
		content: '\e88e';
		font-size: 22px;
		padding-right: 5px;
		color: $color-error;
	}

	+ .edi-tooltip-notify-close-btn {
		margin-left: 271px;
		margin-top: 14px;
		position: fixed;
		z-index: 10000;

		.ui-button-icon {
			.ui-button-glyph {
				color: $color-grey-50 !important;
			}
		}
	}

	span {
		white-space: normal;
	}
}

.ui-button-user_button {
	margin-left: 24px;
	margin-right: 8px;

	.ui-button-button {
		justify-content: flex-start;

		&::after {
			content: '\e5d3';
			font-family: Material Icons, sans-serif;
			transform: translate(-20%, -50%) rotate(90deg);
		}

		.ui-button-inner {
			@extend .caption_02;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-right: 13px;
		}
	}
}

.ui-button-orgs_switch_button {
	margin-left: 24px;

	.ui-button-button {
		justify-content: flex-start;

		.ui-button-inner {
			@extend .caption_02;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
