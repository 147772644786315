@use '@UIkit/styles/typography/heading';
@use '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables';
$this: '.ui-org-selector';

#{$this} {
	position: relative; //заглушка что бы класс создался
	.ui-simple-selector-details-container {
		.edi-company-name {
			@extend .body-short_01;
		}

		.ui-simple-selector-details-container-body {
			.edi-company-row {
				.row-data-separator {
					margin: 0;
				}
			}
		}
	}

	.edi-company-structural-subdivision {
		word-wrap: break-word;
	}

	&-old-cp {
		margin: 10px 10px 5px 10px;

		.ui-simple-selector-title {
			.ui-label-text {
				@extend .heading_01;
				color: $color-dark;
				display: block;
				margin: 8px 0;
			}
		}
	}
}

#{$this}-modal {
	.x-panel-body-default {
		background-color: transparent;
	}
}
