@import '@UIkit/styles/global/Colors/Variables.scss';

/* -------- страницы входа -------- */
BODY.edi-viewport {
	.edi-login-logo {
		display: block;
		background: url(../coreplat/images/login_logo.png) center center/contain no-repeat;
		height: 40px;
		margin: auto;
	}

	//сделаем фон окна логина ССО был прозрачный, что бы не подстаривать размеры фрейма под окно внутри, т.к. они меняются
	//на самой странице ССО надо так же вырубить фоны, иначе толку ноль от этой затеи
	.edi-login-sso-window {
		background-color: transparent;
		box-shadow: none;

		.x-panel-body {
			background-color: transparent;
		}
	}

	&.edi-login-form-displayed {
		&.edi-viewport-with-background {
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
			background-color: #3d7dca;
		}

		.edi-login-form-window {
			z-index: 90000 !important;
		}

		.x-tip,
		.ui-tooltip {
			z-index: 90100 !important;
		}

		&.edi-viewport-background-winter-forest {
			background-image: url('backgrounds/winter-forest.jpg');
		}

		&.edi-viewport-background-winter-forest2 {
			background-image: url('backgrounds/winter-forest2.jpg');
		}

		&.edi-viewport-background-deer {
			background-image: url('backgrounds/deer.jpg');
		}

		&.edi-viewport-background-sea-cliffs {
			background-image: url('backgrounds/sea-cliffs.jpg');
		}

		&.edi-viewport-background-flower-with-dew {
			background-image: url('backgrounds/flower-with-dew.jpg');
		}

		&.edi-viewport-background-cloud-hill {
			background-image: url('backgrounds/cloud-hill.jpg');
		}

		&.edi-viewport-background-leaf-with-dew {
			background-image: url('backgrounds/leaf-with-dew.jpg');
		}

		&.edi-viewport-background-ferris-weel {
			background-image: url('backgrounds/ferris-weel.jpg');
		}

		&.edi-viewport-background-red-leaf {
			background-image: url('backgrounds/red-leaf.jpg');
		}

		&.edi-viewport-background-mountain-slope {
			background-image: url('backgrounds/mountain-slope.jpg');
		}

		&.edi-viewport-background-spring-leaves {
			background-image: url('backgrounds/spring-leaves.jpg');
		}

		&.edi-viewport-background-balloons {
			background-image: url('backgrounds/balloons.jpg');
		}

		&.edi-viewport-background-sand-beach {
			background-image: url('backgrounds/sand-beach.jpg');
		}

		&.edi-viewport-background-sakura {
			background-image: url('backgrounds/sakura.jpg');
		}

		&.edi-viewport-background-hills {
			background-image: url('backgrounds/hills.jpg');
		}

		&.edi-viewport-background-forest-glade {
			background-image: url('backgrounds/forest-glade.jpg');
		}

		&.edi-viewport-background-alfa-bank {
			background-image: url('backgrounds/alfa-bank.jpg');
		}

		&.edi-viewport-background-sparks {
			background-image: url('backgrounds/sparks.jpg');
		}

		&.edi-viewport-background-month-1 {
			background-image: url('backgrounds/monthly/01.jpg');
		}

		&.edi-viewport-background-month-2 {
			background-image: url('backgrounds/monthly/02.jpg');
		}

		&.edi-viewport-background-month-3 {
			background-image: url('backgrounds/monthly/03.jpg');
		}

		&.edi-viewport-background-month-4 {
			background-image: url('backgrounds/monthly/04.jpg');
		}

		&.edi-viewport-background-month-5 {
			background-image: url('backgrounds/monthly/05.jpg');
		}

		&.edi-viewport-background-month-6 {
			background-image: url('backgrounds/monthly/06.jpg');
		}

		&.edi-viewport-background-month-7 {
			background-image: url('backgrounds/monthly/07.jpg');
		}

		&.edi-viewport-background-month-8 {
			background-image: url('backgrounds/monthly/08.jpg');
		}

		&.edi-viewport-background-month-9 {
			background-image: url('backgrounds/monthly/09.jpg');
		}

		&.edi-viewport-background-month-10 {
			background-image: url('backgrounds/monthly/10.jpg');
		}

		&.edi-viewport-background-month-11 {
			background-image: url('backgrounds/monthly/11.jpg');
		}

		&.edi-viewport-background-month-12 {
			background-image: url('backgrounds/monthly/12.jpg');
		}
	}

	.edi-login-frame {
		visibility: hidden;
		width: 0;
		height: 0;
		position: absolute;
	}

	.edi-login-frame-sso {
		width: 100%;
		height: 100%;
		border: none;
		overflow: hidden;
	}

	.edi-login-form-window {
		border-radius: 10px;

		.x-window-header {
			background: transparent;
			border-color: transparent;

			.x-title-text {
				color: $color-white;
			}
		}

		.edi-login-form__field {
			position: relative;

			input {
				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus,
				&:-webkit-autofill:active {
					-webkit-box-shadow: 0 0 0 30px $color-white inset !important;
					-moz-box-shadow: 0 0 0 30px $color-white inset !important;
					box-shadow: 0 0 0 30px $color-white inset !important;
				}
			}
		}

		.edi-login-error-label {
			.ui-label-text {
				word-break: normal;
			}
		}
	}

	.edi-viewport-footer {
		z-index: 90000;

		.x-panel-body {
			background: transparent;
			text-align: center;
			vertical-align: middle;
			line-height: 80px;
		}

		.edi-login-footer-link {
			display: inline-block;
			color: $color-grey-50;
			text-decoration: none;
			margin: 0 12px;

			span {
				color: $color-white;
			}
			&:hover {
				color: $color-grey-50;

				span {
					color: $color-grey-50;
					text-decoration: underline;
				}
			}
		}

		.edi-login-footer-contact-email:before {
			font-family: 'Material Icons', sans-serif;
			content: '\E0D0';
			font-size: 22px;
			padding-right: 5px;
			vertical-align: middle;
		}

		.edi-login-footer-contact-phone:before {
			font-family: 'Material Icons', sans-serif;
			content: '\E0CF';
			font-size: 22px;
			padding-right: 5px;
			vertical-align: middle;
		}
	}

	.edi-restore-password-error {
		text-align: center;
		a {
			color: #3d7dca;
			text-decoration: none;
		}
	}
}
