@use '@UIkit/styles/typography/heading';
@use '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables';

.edi-rows-block.x-panel {
	overflow: visible;

	.x-panel-header {
		background-color: inherit;
		padding-left: 0 !important;

		.x-title-text {
			@extend .heading_01;
			padding: 0 0 16px 0;
			color: #1d252c;
		}
	}

	.x-panel-bodyWrap {
		overflow: visible;

		.x-panel-body {
			overflow: visible;
		}
	}

	.row-block-buttons-container {
		//что бы компенсировать у полей отступ сверху для летающего лэйбла
		margin-top: 8px;

		.edi-row-block-add_button {
			top: 2px !important;
		}

		.edi-row-block-remove_button {
			top: -1px !important;
			margin-right: 16px !important;
		}
	}

	.edi-row-block-toolbar {
		background-color: transparent;
		padding: 0;
	}
}
