/* -------- document-filter - панель фильтров -------- */
BODY.edi-viewport {
	.document-filter {
		.two-column {
			padding-left: 17px !important;
			padding-right: 17px !important;
		}

		.two-column-no-padding {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		.x-form-item-label {
			color: #757575;
		}
	}

	.document-filter-floating-header {
		background-color: #ffffff;
	}

	.document-filter-floating.document-filter-with-backdrop {
		box-shadow: 0 4px 8px 0 #bbbdbf40;
	}

	.document-filter-floating-header .edi-filter-form-chips-container .edi-filter-chip-button {
		margin-left: 8px;
		background-color: #ffffff;
		border: 1px solid #d2d3d5;
		border-radius: 20px;
		padding: 5px 8px 5px 14px;
		box-shadow: none;
		cursor: default;
	}

	.document-filter-floating-header .edi-filter-chip-button {
		.x-btn-button {
			height: 20px;

			.x-btn-inner {
				color: #1d252c;
				font-size: 14px;
				font-weight: normal;
				line-height: 20px;
				text-overflow: ellipsis;
			}

			.x-btn-glyph {
				color: #8e9295;
				font-size: 14px;
				font-weight: normal;
				line-height: 20px;
				height: 20px;
				width: 14px;
				cursor: pointer;
			}
		}

		&.x-btn-over {
			.x-btn-glyph {
				color: #1d252c;
			}
		}
	}

	.document-filter-floating .x-panel-body.document-filter-body,
	.document-filter-floating .x-toolbar {
		background-color: #ffffff;
	}

	.document-filter-floating .x-panel-body.document-filter-body {
		overflow: auto;
	}

	.document-filter-floating.document-filter-with-backdrop::after {
		content: '';
		user-select: none;
		position: absolute;
		top: 100%;
		width: 100%;
		height: 5000%;
		background-color: #1d252c;
		opacity: 0.4;
	}

	.filter-backdrop {
		content: '';
		user-select: none;
		position: absolute;
		top: 0;
		width: 100%;
		height: 5000%;
		background-color: #1d252c;
		opacity: 0.4;
		z-index: 5;
	}

	.document-filter-floating-quick-add-button {
		&.x-btn.x-btn-default-small.x-btn-over {
			background-color: transparent;
		}

		.x-btn-button.x-btn-button-default-small {
			.x-btn-glyph,
			.x-btn-inner {
				color: #3d7dca;
			}
		}

		.x-btn-arrow-right:after {
			display: none;
		}
	}

	.document-filter-floating.x-border-region-slide-in,
	.document-filter-floating .edi-toolbar-filter-collapse,
	.document-filter-floating .edi-toolbar-filter-collapse .x-box-inner {
		overflow: visible;
		z-index: 6;
	}

	.document-filter-floating .edi-toolbar-filter-collapse {
		border: none;
	}

	.document-filter-floating .x-toolbar-footer .x-btn {
		background-color: transparent;
	}

	.document-filter-floating .x-toolbar-footer .x-btn-over {
		background-color: #f3f4f4;
	}

	.edi-filter-form-placeholder.document-filter-floating-header,
	.edi-module-filter-form .edi-toolbar-filter-collapse {
		z-index: 6;
		box-shadow: 0 8px 4px -5px #bbbdbf80;
	}

	.edi-filter-form-placeholder .x-panel-bodyWrap,
	.document-filter-floating-header,
	.document-filter-floating-header .x-panel-body,
	.document-filter-floating-header .x-panel-body .x-box-inner,
	.document-filter-floating-header .document-filter-floating-container-expand-button,
	.document-filter-floating-header .document-filter-floating-container-expand-button .x-panel-body,
	.document-filter-floating-header .document-filter-floating-container-expand-button .x-panel-body .x-box-inner {
		overflow: visible !important;
	}

	.document-filter-floating-header {
		.document-filter-floating-expand-button,
		.document-filter-floating-expand-button.x-btn-focus.x-btn-pressed.x-btn-default-small {
			position: absolute !important;
			height: 24px !important;
			margin-top: 0 !important;
			color: #1d252c;
			background-color: #ffffff;
			border: none;
			border-bottom-left-radius: 32px;
			border-bottom-right-radius: 32px;
			padding: 0 10px 0 10px;
			box-shadow: 0 8px 4px -5px #bbbdbf80;
		}

		.document-filter-floating-expand-button .x-btn-inner,
		.document-filter-floating-expand-button .x-btn-glyph {
			line-height: 20px;
			color: #1d252c;
		}

		.document-filter-floating-container-expand-button {
			> .x-box-inner {
				height: 1px !important;
			}
		}
	}

	.x-btn.document-filter-floating-collapse-button,
	.x-btn.document-filter-floating-expand-button {
		.x-btn-wrap {
			.x-btn-button {
				justify-content: space-around;
			}
		}
	}

	.document-filter-floating .x-toolbar .x-btn.document-filter-floating-collapse-button,
	.document-filter-floating .document-filter-floating-collapse-button.x-btn-focus,
	.document-filter-floating .document-filter-floating-collapse-button.x-btn-over,
	.document-filter-floating .document-filter-floating-collapse-button.x-btn-pressed {
		height: 24px !important;
		margin-top: -2px !important;
		color: #1d252c;
		background-color: #ffffff;
		border: none;
		border-bottom-left-radius: 32px;
		border-bottom-right-radius: 32px;
		padding: 0 10px 0 10px;
		box-shadow: 0 8px 4px -5px #bbbdbf80;
	}

	.document-filter-floating .document-filter-floating-collapse-button .x-btn-inner,
	.document-filter-floating .document-filter-floating-collapse-button .x-btn-glyph {
		color: #1d252c;
		line-height: 20px;
	}

	.document-filter-floating {
		.x-toolbar {
			.x-btn.edi-button-filter-collapse {
				margin-top: -10px !important;
				background-color: #ffffff;
				border: none;
				border-radius: 20px;
				padding-top: 0;
				padding-bottom: 7px;
				box-shadow: 0 4px 8px -2px #bbbdbf40;
				.x-btn-button-default-toolbar-small {
					span {
						margin-right: auto;
					}
				}
			}
		}
	}

	.document-filter-floating .x-toolbar-footer {
		.edi-button-filter-search.x-btn,
		.edi-button-filter-search.x-btn.x-btn-over {
			background-color: #3d7dca;

			.x-btn-inner,
			.x-btn-glyph {
				color: #ffffff;
			}
		}
	}

	.document-filter {
		&.document-filter-floating {
			.x-toolbar-footer {
				.x-btn {
					background-color: unset;
					&.x-btn-over {
						background-color: #f3f4f4;
					}
					&.edi-button-filter-search {
						background-color: #3d7dca;
						margin-left: 15px;
					}
					&.edi-button-filter-collapse {
						background-color: #ffffff;
					}
				}

				.x-btn-over {
					background-color: #bbbdbf;
				}
			}
		}
		.x-toolbar {
			.x-btn {
				background-color: #d2d3d5;
				color: #fff;
			}
			.x-btn-over {
				background-color: #bbbdbf;
			}
		}
		.x-toolbar-footer {
			.x-btn {
				padding: 10px;
				border: none;
				border-radius: 3px;

				.x-btn-inner {
					font-size: 14px;
					line-height: 20px;
					font-weight: 400;
					color: #1d252c;
					padding-left: 2px;
					padding-right: 2px;
				}

				.x-btn-glyph {
					color: #1d252c;
				}
			}
		}

		.x-btn {
			.x-btn-icon-el {
				color: #1d252c;
			}
		}
	}

	.edi-module-filter-form {
		.x-toolbar-footer.x-docked {
			.x-btn {
				text-transform: uppercase;
			}
		}
	}

	.x-toolbar {
		.x-field {
			&.edi-filter-form-saved-filters-combo {
				input::placeholder,
				input::-webkit-input-placeholder,
				input::-moz-placeholder,
				input::-ms-input-placeholder {
					color: #1d252c !important;
					opacity: 1;
				}

				.x-form-text {
					font-size: 14px;
					line-height: 15px;
					text-transform: uppercase;
					color: #1d252c;
					padding: 0;
					margin: 8px 0 8px 12px;
					display: block;
					box-shadow: none;
					outline: 0;
					background: transparent;
					&::placeholder {
						color: #1d252c;
						opacity: 1;
					}
				}

				.x-form-trigger {
					display: flex;
					align-items: center;
					justify-content: center;
					color: #1d252c;
				}

				&.x-item-disabled {
					.x-pickerfield-open,
					.x-pickerfield-open .x-form-text,
					&:hover,
					&:hover .x-form-text {
						background-color: white;
					}

					.x-form-text {
						opacity: 0.7;
					}
				}

				&:hover,
				&:hover .x-form-trigger {
					background-color: #f3f4f4;
				}

				.x-form-trigger-wrap {
					display: flex;
					justify-content: space-between;
					.x-form-text-wrap {
						width: 100%;
					}
				}
			}
		}
	}

	.documents-attribute-filter-value-container {
		margin-top: 31px !important;
	}
}
