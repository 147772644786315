.edi-viewport {
	.x-menu {
		border: none;
		outline: none;
		box-shadow: 0 4px 16px rgba(187, 189, 191, 0.5);
		border-radius: 3px;

		.x-menu-item {
			white-space: nowrap;
			overflow: hidden;
			cursor: pointer;

			.x-menu-item-link {
				line-height: 20px;
				padding: 8px 40px 8px 16px;

				.x-menu-item-text {
					font-size: 14px;
					color: #000;
					line-height: 20px;
					margin: 0;

					&.x-menu-item-indent {
						margin-left: 0;
					}
				}

				.x-menu-item-icon {
					width: 20px;
					height: 20px;
					font-size: 20px;
					line-height: 20px;
					top: 8px;
					right: 10px;
					left: auto;
					background-position: center center;
					background-repeat: no-repeat;
				}

				.x-menu-item-icon-right {
					right: 10px;
				}
			}

			&.x-menu-item-active {
				background-image: none;
				background-color: #f3f4f4;

				.x-menu-item-text,
				.x-menu-item-icon {
					color: #000;
					font-weight: 400;
				}
			}

			&.x-menu-item-disabled {
				opacity: 1;
				background-color: transparent;
				cursor: default;

				.x-menu-item-text,
				.x-menu-item-icon {
					font-weight: 400;
					color: #bbbdbf;
				}
			}
		}

		.x-menu-icon-separator {
			display: none;
		}
	}
}
