@use '@UIkit/styles/typography/_text.scss';
@import '@UIkit/styles/global/Colors/Variables.scss';

.ui-filter {
	position: relative;
	z-index: 7;
	background-color: $color-white;
	box-shadow: 0 4px 8px $color-dark-opacity-20;

	&-header-wrapper {
		z-index: 6;
	}

	&-header {
		position: relative !important;
		z-index: 7;
		background-color: $color-white;
		box-shadow: 0 2px 10px 0 $color-dark-opacity-10;
		overflow: visible;
		padding: 12px 8px;

		> .x-panel-bodyWrap {
			overflow: visible;

			> .x-panel-body {
				overflow: visible;
			}
		}

		&-indent {
			margin-bottom: 16px;
		}

		&-after {
			position: absolute;
			left: 0;
			top: 0;
			background: #fff;
			width: 100%;
			height: calc(100% + 16px);
		}
	}

	.ui-filter-toolbar {
		&-collapse {
			padding: 0;
			overflow: visible;
			border: 0;
			transform: translate(0, 12px);

			> .x-box-inner {
				overflow: visible;
			}
		}
		&-buttons {
			background-color: $color-white;
			margin-top: 16px;
			padding: 0 !important;
		}
	}

	&-chip {
		border: 1px solid $color-grey-20;
		border-radius: 16px;
		padding: 3px 8px;
		background-color: $color-white;
		cursor: pointer;

		&:hover {
			background-color: $color-grey-5;
		}

		.x-autocontainer {
			&-innerCt {
				display: flex;
				justify-content: space-around;
				align-items: center;
				gap: 8px;
			}
		}

		&-title {
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			cursor: pointer;

			@extend .body-short_01;
			color: $color-dark;
		}
		&-value {
			@extend .body-short_02;
			color: $color-dark;
		}
	}

	&-floating-container-expand {
		position: absolute;
		overflow: visible;
		transform: translate(0, -4px);
		padding: 0;

		> .x-box-inner {
			//то бы тулбар, в котором лежит выступающая кнопка, не перекрывал собой контент на котором можно кликать
			height: 1px !important;
			overflow: visible;
		}
	}

	&-backdrop {
		display: block;
		position: absolute;
		top: 56px;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color-dark;
		opacity: 0.4;
		z-index: 5;
	}

	&-saved-combobox {
		border-radius: 4px;

		.x-form {
			&-trigger-wrap {
				border: none;
				color: $color-dark;
			}
			&-field {
				cursor: pointer;
				background: transparent;
				padding: 10px 16px 11px 16px;
				color: $color-dark;

				@extend .body-short_02;
			}
		}

		.ui-field-status-required {
			display: none;
		}
	}

	&-hidden-field {
		width: 0 !important;
		height: 0 !important;
		opacity: 0 !important;
		margin: 0 !important;
	}
}

.ui-button-chip {
	$baseCls: '.ui-button';

	#{$baseCls}-glyph {
		width: 20px;
		height: 20px;
		line-height: 20px;
		font-size: 20px;
		color: $color-grey-50;
	}
	&:hover {
		background-color: $color-grey-5;
		color: $color-dark;

		#{$baseCls}-glyph {
			color: $color-dark;
		}
	}
}

.ui-button-expand {
	$baseCls: '.ui-button';

	position: relative;
	cursor: pointer;

	#{$baseCls}-button {
		gap: 0;
		background-color: transparent;
		border-radius: 16px;
		height: 32px;
		padding: 0 16px;
	}
	#{$baseCls}-inner {
		@extend .caption_01;
		color: $color-grey-50;
	}
	#{$baseCls}-glyph {
		color: $color-grey-50;
	}

	&::before {
		content: '';
		position: absolute;
		top: 11px;
		left: 0;
		width: 100%;
		height: 16px;
		border-radius: 0 0 16px 16px;
		background-color: $color-white;
		box-shadow: 0 2px 4px $color-dark-opacity-10;
		cursor: inherit;
	}
}

.ui-filter,
.edi-filter-chip-modal {
	.x-form-fieldcontainer {
		label {
			padding: 8px 0 0 5px;
			vertical-align: middle;
		}
	}
}
