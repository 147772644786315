@use '@UIkit/styles/typography/_text.scss';
@import '@UIkit/styles/global/Colors/Variables.scss';

/* -------- edi-navigation-panel - панель навигации -------- */
.edi-navigation-panel {
	overflow: hidden;
	box-shadow: 0 4px 8px $color-dark-opacity-20;

	&-inner {
		border-collapse: separate;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;

		&-body {
			overflow-x: hidden !important;
			padding-top: 8px;
			padding-bottom: 54px;
			background-image: none;
		}
	}

	.x-title.x-panel-header-title {
		.x-title-glyph {
			color: $color-grey-50;
			font-size: 20px;
			width: auto !important;
			height: auto !important;
		}
	}

	&-hover {
		overflow: visible;
		box-shadow: none;

		.edi-navigation-panel-inner {
			z-index: 90100;
			box-shadow: 0 32px 48px $color-grey-50;
		}

		.categoryItemHeader {
			background: $color-blue-opacity-10;
			border-radius: 3px;
			width: auto !important;
		}
	}

	&-closed {
		.active {
			.categoryItemHeader {
				width: 40px !important;
			}
		}

		.categoryItemBody {
			display: none;
		}

		.edi-navigation-panel-inner-body {
			overflow: hidden !important;
			background-image: none;
		}
	}

	.categoryItem {
		width: 304px;

		.categoryItemHeader,
		.subCategoryItemHeader {
			background-color: transparent;
			border: none;
			padding: 10px 0 10px 10px;
			margin: 4px 8px;
			border-radius: 4px;

			.x-title-text {
				@extend .body-short_01;
				cursor: pointer;
				white-space: normal;
			}

			&:hover {
				background: $color-grey-5;
			}
		}

		.categoryItemHeader {
			.x-title-text {
				padding-left: 16px;
			}

			.categoryItemHeader-icon {
				padding: 0;
				display: none;
				right: 14px !important;
				left: auto !important;
				top: 2px !important;
				border: none;
				background-color: transparent;

				.x-btn-icon-el {
					font-size: 20px;
					width: 20px;
					height: 20px;
					color: $color-grey-50;
				}

				.x-btn-inner {
					width: 20px;
					height: 20px;
				}

				.x-btn-glyph {
					-ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2)';
					transform: rotate(180deg);
				}
			}

			&:hover {
				.categoryItemHeader-icon {
					display: inline-block;
				}
			}
		}

		&.active {
			.categoryItemHeader {
				width: auto;

				.x-title-text,
				.x-title-glyph {
					color: $color-blue;
				}
			}

			.menuItem.active {
				border-radius: 4px;
				background: $color-blue-opacity-10;
				.menuItemBody {
					color: $color-blue;
				}
			}
		}

		&.x-panel-collapsed {
			.categoryItemHeader-icon {
				.x-btn-glyph {
					-ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0)';
					transform: rotate(0deg);
				}
			}
		}

		.categoryItemSingleBody {
			border: none;
			user-select: none;
		}

		.categoryItemBody {
			padding: 0;
			border: none;

			.menuItem {
				.menuItemBody {
					@extend .body-long_01;
					color: $color-dark;
					background-color: transparent;
					user-select: none;
				}
			}

			.menuItem,
			.subCategoryItemHeader {
				border: none;
				border-radius: 4px;
				margin: 0 8px;
				padding: 10px 5px 10px 48px;
				cursor: pointer;
			}

			.menuItem:hover,
			.subCategoryItemHeader:hover {
				background-color: $color-blue-opacity-10;
			}

			.subCategoryItem.x-panel-collapsed .subCategoryItemHeader .x-title-glyph {
				-ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)';
				transform: rotate(270deg);
			}

			.subCategoryItem {
				.subCategoryItemHeader {
					border: none;
					border-radius: 4px;
					margin: 0 8px;
					padding: 10px 5px 10px 48px;
					user-select: none;

					.x-title-text {
						display: block;
					}

					.x-title-icon-wrap {
						position: absolute;
						//24px размер иконки + 16px отступ от текста
						left: -40px;
					}
				}
				.subCategoryItemBody {
					border: none;
					user-select: none;

					.menuItem {
						padding-left: 72px;
						user-select: none;
					}
				}
			}
		}
	}

	.categoryItem-with-delimiter {
		border-top: 1px solid $color-blue-opacity-10;
	}
}
