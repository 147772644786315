@use '@UIkit/styles/typography/_heading.scss';
@use '@UIkit/styles/typography/_text.scss';
@import '@UIkit/styles/global/Colors/Variables.scss';

.edi-module-actions {
	padding: 16px 24px 8px 24px;
	background-color: $color-white;
	box-shadow: 0 2px 10px 0 $color-dark-opacity-10;
	z-index: 99;

	.ui-button-action {
		margin-top: 4px;
	}
}

.edi-info-top-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.edi-detail-info-linebreak {
		width: 100%;
		height: 0;
	}

	.edi-detail-info-doctype,
	.edi-detail-info-number {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.edi-detail-info-doctype {
		@extend .heading_02;
	}

	.edi-detail-info-number {
		@extend .body-short_01;
		color: $color-grey-50;
		margin-top: 4px;
	}
}

//TODO перенести в кит
.ui-tab-body {
	background-color: transparent !important;
}

.edi-documents-grid.ui-table {
	.x-grid-body {
		.x-grid-view {
			.x-grid-item {
				.x-grid-row {
					.x-action-col-cell:has(.edi-grid-row-button-package-details) {
						position: unset;

						.x-grid-cell-inner-action-col {
							$colIcon: '.x-action-col-icon';
							#{$colIcon} {
								color: transparent;
								opacity: 1;

								&.x-item-disabled {
									pointer-events: none;
									cursor: default;
									opacity: 0;
								}

								&:after {
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									content: '\e2c8';
									color: $color-blue;
									font-family: Material Icons, sans-serif;
									font-weight: normal;
									font-style: normal;
									font-size: 20px;
									line-height: 20px;
								}
							}

							&:hover {
								#{$colIcon}:after {
									content: '\e2c7';
								}
							}
						}
					}
				}
			}
		}
	}
}
