/* -------- grids  -------- */
.edi-viewport {
	.edi-coreplat-delivery-point {
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 21px;
		color: #1d252c;
	}
	input.edi-coreplat-delivery-point {
		color: #000 !important;
		padding: 4px 6px 3px 6px;
		background: #fff repeat-x 0 0;
		border: 0;
		margin-bottom: 2px;
		border-collapse: separate;
		box-shadow: 0 1px 0 0 silver;
		height: 24px;
		line-height: 15px;
	}

	.edi-delivery-grid-panel {
		padding-top: 5px;
		width: 100% !important;
	}

	.edi-documents-grid {
		.edi-documents-grid__total-warning-label {
			color: #a61120;
		}

		.ui-button.edi-documents-grid__total-warning-details-button {
			.ui-button-inner,
			.ui-button-icon-el {
				color: #a61120;
				text-decoration: underline;
			}
		}

		&.ui-table {
			.x-grid-body {
				.x-grid-empty {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					padding: 0;
					border: none;

					.edi-empty-text-with-button {
						text-align: center;
						.icon-empty-text {
							background-image: url(images/grid/empty_text.png?ver=20150702?ver=20150702);
							width: 216px;
							height: 182px;
							background-size: contain;
							margin: 0 auto;
						}
						p {
							padding: 5px;
							margin: 0;
							font-size: 14px;
						}
						.edi-empty-btn {
							margin: 0 auto;
							border: none;
							background: none;
							color: #3d7dca;
							font-size: 14px;
							font-weight: 500;
							cursor: pointer;
						}
					}
				}
			}
		}
	}

	.invalid {
		color: #f34235;
		font-weight: 400;
	}

	.document-passport-time-mark-grid {
		.edi-grid-cell-flex {
			display: flex;
			height: 20px;
		}

		.x-grid-row {
			.edi-grid-cell-inner-button {
				opacity: 0;
			}

			&:hover {
				.edi-grid-cell-inner-button {
					opacity: 1;
					position: relative;
					cursor: pointer;

					.x-btn-icon {
						background-color: transparent;
						border: none;
					}
				}
			}
		}
	}
}
.ui-table {
	.x-grid-body {
		.x-grid-view {
			.x-grid-item {
				.x-grid-row {
					.x-grid-cell {
						&.column-error-description {
							.x-grid-cell-inner {
								white-space: normal;
								padding-top: 5px;
								padding-bottom: 5px;
							}
						}
					}
				}
			}
		}
	}
}
